<template>
  <main class="report_group" id="content">
    <div class="report_box">
      <!-- 이용약관 -->
      <div class="terms-title">
        <h3>{{ $t('content.signup.popup.Terms.txt01') }}</h3>
      </div>

      <div class="view-box">
        <TermsText />
      </div>
    </div>
  </main>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import TermsText from './component/TermsText.vue';

/**
 * 이용약관
 */
export default {
  name: 'MobileTerms',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    TermsText,
  },
};
</script>
